import React from "react";
import { firestore} from '../../utils/firebase.utils';
import {project} from "../../utils/constant.urls";

export const getUserOffer = async (taskid: string, uid: string) => {
  const querySnapshot = await firestore
    .collection("tasks")
    .where("project", "==", project)
    .where("uid", "==", taskid)
    .get();
  if (!querySnapshot.empty) {
    const taskDoc = querySnapshot.docs[0];
    const offerDoc = await taskDoc.ref.collection("offers").doc(uid).get();
    // if (offerDoc.exists && offerDoc.project === project) {
    if (offerDoc.exists) {
      return offerDoc.data();
    } else {
      return null;
    }
  } else {
    return null; // No documents matching the query
  }
};


export const taskStatus = (task,uid) => {
         if (uid) {
              uid = uid;
         }
         if (task) {
              if (task.uid === uid) {
                   if (task.isDispute) {
                        return "disputed-task";
                   } else if (task.isCancelled) {
                        return "cancelled-task";
                   } else if (task.isExpired) {
                        return "postpone-task";
                   }
                   if (task.offers === 0 && !task.isComplete && !task.isAssigned) {
                        return "awaiting-offers";
                   } else if (task.offers > 0 && !task.isComplete && !task.isAssigned) {
                        return "review-offers";
                   } else if (!task.isComplete && task.isAssigned && !task.requestPayment) {
                        return "assigned-to-owner";
                   } else if (!task.isComplete && task.isAssigned && task.requestPayment) {
                        return "release-payment";
                   } else if (task.isComplete && task.isAssigned && !task.taskOwnerHasReviewed) {
                        return "leave-review-owner";
                   } else if (task.isComplete && task.isAssigned && task.taskOwnerHasReviewed) {
                        return "completed-owner";
                   } else {
                        return "";
                   }
              } else {
                   if (task.isDispute) {
                        return "disputed-task";
                   } else if (task.isExpired) {
                        return "expired";
                   } else if (task.isCancelled) {
                        return "task-cancelled";
                   } else if (task.offerUIDs?.indexOf(uid) === -1 && !task.isComplete && !task.isAssigned) {
                        return "open";
                   } else if (task.offerUIDs?.indexOf(uid) !== -1 && !task.isComplete && !task.isAssigned) {
                        return "has-offer";
                   } else if (task.assignedWorker.uid !== uid && !task.isComplete && task.isAssigned) {
                        return "assigned";
                   } else if (task.offerUIDs.indexOf(uid) !== -1 && !task.isComplete && task.isAssigned && task.assignedWorker.uid === uid) {
                        return "assigned-to-tasker";
                   } else if (task.offerUIDs.indexOf(uid) === -1 && task.isComplete && task.isAssigned) {
                        return "completed";
                   } else if (task.assignedWorker.uid === uid && task.isComplete && task.isAssigned && !task.workerHasReviewed) {
                        return "leave-review-tasker";
                   } else if (task.assignedWorker.uid === uid && task.isComplete && task.isAssigned && task.workerHasReviewed) {
                        return "completed-tasker";
                   } else {
                        return "";
                   }
              }
         } else {
              return "";
         }
     };

//     export  const getOwnerTasks = async (uid,addressLoading,fetchUserAvatars,posterTask,offersAvatars) => {
//           let d = new Date();
//           const dispatch = useDispatch();
//           let minDate = new Date(d.setDate(d.getDate() - 14));
//           let userId = uid;
//           let tasksRef = firestore.collection('tasks');
//           let tasks: Task[] = [];
//           let OffersAvatarsList = [];
//           const tasksList = await tasksRef.where("uid", "==", userId)
//                .where("dateToStart", ">", minDate)
//                .orderBy("dateToStart")
//                .get();
//           for (let index = 0; index < tasksList.docs.length; index++) {
//                let element = tasksList.docs[index].data();
//                element.id = tasksList.docs[index].id;
//                const status = taskStatus(element,uid);
//                if (status === "review-offers") {
//                     let offer = await getUserOffer(element?.id , uid);
//                     let avatarsList = await fetchUserAvatars(element?.offerUIDs);
//                     OffersAvatarsList.push({ taskId: element?.id, avatars: avatarsList, taskOffer: offer });
//                }
//                tasks.push(new Task(element));
//           }
//           posterTask(tasks);
//           offersAvatars(OffersAvatarsList);
//           if (addressLoading && tasks.length <= 0) {
//                privateUsers
//                     .doc(uid)
//                     .get()
//                     .then(async (privateDoc) => {
//                          let data = privateDoc.data();
//                          let addressList: Address[] = [];
//                          let addressSnapshots = await privateUsers.doc(uid).collection("addresses").get();
//                          if (addressSnapshots.size > 0) {
//                               addressSnapshots.forEach((doc) => {
//                                    let addressData = doc.data();
//                                    addressData.id = doc.id;
//                                    addressList.push(new Address(addressData));
//                               });
//                          }
//                          dispatch(
//                               updateAddress({
//                                    addresses: addressList,
//                                    defaultTaskAddress: data?.defaultTaskAddress?.toString() ?? "",
//                                    defaultBillingAddress: data?.defaultBillingAddress?.toString() ?? ""
//                               })
//                          );
//                     });
//           }
//      };
