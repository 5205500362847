import React from "react";
import Text from "../data-display/text";
import { navigate } from "gatsby";
import { Task } from '../../models/task';
import Avatar from "../../components/avatar/avatar";
import PostTaskWrapper from "../../components/post-task/post-task-wrapper";
import MakeOffer from "../../components/task-components/offers/make-offer";
import { TaskOffer } from "../../models/task-offer";
import NumberFormat, { NumericFormat } from "react-number-format";
import {taskStatus} from "./helpers";
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/index.reducers';
import { environment } from "../../environments/quickTaskEnvironemt";
  import {useTheme} from "@mui/material";
import {currencySymbol, isQuickTaskProject, title} from "../../utils/constant.urls";
const QuikTaskMainColor = environment.mainColor;
const mainPaletteColor = isQuickTaskProject ? QuikTaskMainColor : "rgba(78, 62, 253, 1.0)";
interface TaskStatusIndecatorProps {
  task: Task;
  tasksOffersAvatars: {
    taskId: string;
    avatars: {name: string; profilePicture: string}[];
    taskOffer: TaskOffer;
  }[];
  taskEdit: any;
}

const TaskStatusIndicator = ({task, tasksOffersAvatars, taskEdit}: TaskStatusIndecatorProps) => {
  //     const [taskToEdit, setTaskToEdit] = useState<Task | null>(null);
  const {userData} = useSelector((state: RootState) => state.userData);
  const theme = useTheme();
  const styles = {
    container: {
      paddingTop: "24px",
    },
    PostTaskButton: {
      marginLeft: "12px",
      transition: "0.3s",
      borderRadius: "50px",
      cursor: "pointer",
      padding: "6px 14px",
      display: "flex",
      alignItems: "center",
      color: isQuickTaskProject ? QuikTaskMainColor : theme.palette.primary.main,
      "&:hover": {
        background: "rgba(78, 62, 253,0.13)",
      },
    },

    drawerPaper: {
      width: "calc(1280px/3)",
      left: "calc((100% - 1280px)/2)",
      height: "calc(100vh - 88px)",
      border: "none",
      background: "none",
      top: "88px",
    },
    card: {
      display: "flex",
      flexDirection: "column",
      background: "white",
      position: "relative",
      borderRadius: "6px",
      cursor: "pointer",
      boxShadow: "0 1px 2px rgb(0,0,0,0.1)",
      transition: "0.3s",
      border: "1.5px solid rgb(245,245,245)",
      "&:hover": {
        boxShadow: "0 10px 20px -14px rgba(50,50,50,0.3)",
      },
    },
    selectedCard: {
      boxShadow: "0 10px 20px -14px rgba(50,50,50,0.3)",
      border: "1.5px solid rgb(220,220,220)",
    },
    formControl: {
      marginTop: "24px",
      minWidth: 120,
      width: "300px",
      background: "transparent",
      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        border: "2px solid #d3d3d4",
        background: "transparent",
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: "2px solid #d3d3d4",
        background: "transparent",
      },
    },
    statusIndicator: {
      borderRadius: "24px",
      padding: "1.5px 12px",
      paddingBottom: "3.5px",
      textAlign: "center",
    },
    selectRoot: {
      padding: "12px",
      display: "flex",
      alignItems: "center",
    },
    listItemRoot: {
      minWidth: "40px",
    },
    dropdownStyle: {
      border: "2px solid #d3d3d4",
      boxShadow: "none",
    },
    filtersContainer: {
      position: "fixed",
      width: "calc(1280px/3.1)",
      paddingBottom: "10px",
      background: theme.palette.background.default,
      zIndex: 1300,
      left: "calc((100% - 1280px)/2)",
    },
    rootTabContainer: {
      transform: "translateY(4px)",
      background: "white",
      padding: "0 24px",
      borderRadius: "6px",
    },
    rootTab: {
      textTransform: "none",
      padding: "0",
      minWidth: "unset",
      justifyContent: "flex-start !important",
      fontSize: theme.typography.pxToRem(15),
      marginRight: "48px",
    },
    indicatorTab: {
      display: "flex",
      height: "4px",
      justifyContent: "center",
      backgroundColor: isQuickTaskProject ? QuikTaskMainColor : theme.palette.primary.main,
      "& > div": {
        width: "100%",
        backgroundColor: isQuickTaskProject ? QuikTaskMainColor : theme.palette.primary.main,
      },
    },
  };
  const RenderOffersAvatars = (task: Task) => {
    const taskDetails = new Task(task);
    return (
      <div style={{display: "flex", flexDirection: "row"}}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            margin: "auto 0px",
            marginLeft: "15px",
            marginBottom: "25px",
          }}
        >
          {tasksOffersAvatars.find((item) => item.taskId === task?.id)?.avatars &&
          tasksOffersAvatars.find((item) => item.taskId === task?.id)?.avatars?.length > 0
            ? tasksOffersAvatars
                .find((item) => item.taskId === task?.id)
                ?.avatars.map((a, i) => {
                  return (
                    <Avatar
                      key={i}
                      profilePicture={a.profilePicture}
                      name={a.name}
                      size={20}
                      style={{
                        position: "absolute",
                        transform: `translateX(${12 * i}px)`,
                      }}
                    />
                  );
                })
            : taskDetails?.offerUIDs.map((o, i) => {
                if (i <= 3)
                  return (
                    <Avatar
                      key={i}
                      profilePicture={""}
                      name={""}
                      size={20}
                      style={{
                        position: "absolute",
                        transform: `translateX(${12 * i}px)`,
                      }}
                    />
                  );
                else return <div key={i} />;
              })}
        </div>
        <div
          style={{
            width:
              taskDetails.offerUIDs.length <= 3
                ? `${taskDetails.offerUIDs.length * 13 + 10}px`
                : "48px",
          }}
        />
        <Text variant={"body2"} bold style={{color: "rgb(180,180,180)", marginLeft: "10px"}}>
          {taskDetails.offers} Offer{taskDetails.offers > 1 ? "s" : ""}
        </Text>
      </div>
    );
  };

  switch (taskStatus(task, userData.uid)) {
    case "cancelled-task":
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "auto",
            marginBottom: "auto",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "fit-content",
              marginBottom: "auto",
              marginLeft: "15px",
            }}
          ></div>
          <PostTaskWrapper task={task}>
            <div
              style={{
                display: "flex",
                cursor: "pointer",
                flexDirection: "column",
                width: "fit-content",
                marginBottom: "auto",
                marginRight: "15px",
              }}
            >
              <Text
                style={{
                  ...(styles.statusIndicator as React.CSSProperties),
                  padding: "5px 5px",
                  background: mainPaletteColor,
                  width: "84px",
                  wordBreak: "unset",
                  color: "white",
                  textAlign: "center",
                }}
                variant={"caption"}
                bold
              >
                Repost Task
              </Text>
            </div>
          </PostTaskWrapper>
        </div>
      );
    case "postpone-task":
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "auto",
            marginBottom: "auto",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "fit-content",
              marginBottom: "auto",
              marginLeft: "15px",
            }}
          >
            <div style={{display: "flex", flexDirection: "row"}}>
              <Text variant={"body2"} medium style={{color: "gray"}}>
                {`Expired ${parseInt(
                  "" +
                    (new Date().getTime() - new Date(task?.dateToStart).getTime()) /
                      (1000 * 3600 * 24),
                  0
                )} days ago`}
              </Text>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              cursor: "pointer",
              flexDirection: "column",
              width: "fit-content",
              marginBottom: "auto",
              marginRight: "15px",
            }}
          >
            <Text
              onClick={() => {
                taskEdit(task);
                navigate(`/my-tasks/poster${task?.slug_title}/edit`);
              }}
              style={{
                ...(styles.statusIndicator as React.CSSProperties),
                padding: "5px 5px",
                background: mainPaletteColor,
                width: "84px",
                wordBreak: "unset",
                color: "white",
                textAlign: "center",
              }}
              variant={"caption"}
              bold
            >
              Repost Task
            </Text>
          </div>
        </div>
      );
    case "review-offers":
      return (
        <div
          style={{
            display: "flex",
            cursor: "pointer",
            justifyContent: "space-between",
            marginTop: "auto",
            marginBottom: "auto",
          }}
        >
          {RenderOffersAvatars(task)}
          <div
            style={{
              display: "flex",
              cursor: "pointer",
              flexDirection: "column",
              width: "fit-content",
              marginBottom: "auto",
              marginRight: "15px",
            }}
          >
            <Text
              onClick={() => {
                navigate(`/my-tasks/poster/${task?.id}/offers/review`);
              }}
              style={{
                ...(styles.statusIndicator as React.CSSProperties),
                padding: "5px 5px",
                background: "rgb(50, 199, 161)",
                width: "89px",
                wordBreak: "unset",
                color: "white",
                textAlign: "center",
              }}
              variant={"caption"}
              bold
            >
              Review Offers
            </Text>
          </div>
        </div>
      );
    case "awaiting-offers":
      return (
        <Text variant={"body2"} bold style={{color: "rgb(160,160,160)", margin: "auto"}}>
          {" "}
          Awaiting Offers from Taskers
        </Text>
      );
    case "assigned-to-owner":
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "auto",
            marginBottom: "auto",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "fit-content",
              marginBottom: "auto",
              marginLeft: "15px",
            }}
          >
            <div style={{display: "flex", flexDirection: "row"}}>
              <Avatar
                userId={task?.assignedWorker?.uid}
                profilePicture={task?.assignedWorker?.profilePicture}
                name={task?.assignedWorker?.name}
                size={20}
                style={{
                  margin: "auto",
                }}
              />
              <Text variant={"body2"} medium style={{color: "gray", marginLeft: "10px"}}>
                Assigned to{" "}
              </Text>
              <Text variant={"body2"} medium bold style={{color: "black"}}>
                {task.assignedWorker.name.split(" ")[0]}
              </Text>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              cursor: "pointer",
              flexDirection: "column",
              width: "fit-content",
              marginBottom: "auto",
              marginRight: "15px",
            }}
          >
            <Text
              onClick={() => {
                navigate(`/messaging/${task?.id}_${task?.assignedWorker?.uid}`);
              }}
              style={{
                ...(styles.statusIndicator as React.CSSProperties),
                padding: "5px 10px",
                background: "rgb(50 199 161)",
                width: "102px",
                wordBreak: "unset",
                color: "white",
                textAlign: "center",
                backgroundColor: mainPaletteColor,
              }}
              variant={"caption"}
              bold
            >
              Send Message
            </Text>
          </div>
        </div>
      );
    case "release-payment":
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "auto",
            marginBottom: "auto",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "fit-content",
              marginBottom: "auto",
              marginLeft: "15px",
            }}
          >
            <div style={{display: "flex", flexDirection: "row"}}>
              <Avatar
                profilePicture={task?.assignedWorker?.profilePicture}
                name={task?.assignedWorker?.name}
                size={20}
                style={{
                  margin: "auto",
                }}
              />
              <Text variant={"body2"} medium style={{color: "gray", marginLeft: "10px"}}>
                Assigned to{" "}
              </Text>
              <Text variant={"body2"} medium bold style={{color: "black"}}>
                {task.assignedWorker.name.split(" ")[0]}
              </Text>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              cursor: "pointer",
              flexDirection: "column",
              width: "fit-content",
              marginBottom: "auto",
              marginRight: "15px",
            }}
          >
            <Text
              onClick={() => {
                navigate(`/my-tasks/poster${task?.id}/release-payment`);
              }}
              style={{
                ...(styles.statusIndicator as React.CSSProperties),

                padding: "5px 10px",
                background: "rgb(50 199 161)",
                width: "100px",
                wordBreak: "unset",
                color: "white",
                backgroundColor: mainPaletteColor,
                textAlign: "center",
              }}
              variant={"caption"}
              bold
            >
              Release Payment
            </Text>
          </div>
        </div>
      );
    case "completed-owner":
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "auto",
            marginBottom: "auto",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "fit-content",
              marginBottom: "auto",
              marginLeft: "15px",
            }}
          >
            <div style={{display: "flex", flexDirection: "row"}}>
              <Avatar
                profilePicture={task?.assignedWorker?.profilePicture}
                name={task?.assignedWorker?.name}
                size={20}
                style={{
                  margin: "auto",
                }}
              />
              <Text variant={"body2"} medium style={{color: "gray", marginLeft: "10px"}}>
                Completed by{" "}
              </Text>
              <Text variant={"body2"} medium bold style={{color: "black"}}>
                {task.assignedWorker.name.split(" ")[0]}
              </Text>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              cursor: "pointer",
              flexDirection: "column",
              width: "fit-content",
              marginBottom: "auto",
              marginRight: "15px",
            }}
          >
            <PostTaskWrapper task={task}>
              <Text
                style={{
                  ...(styles.statusIndicator as React.CSSProperties),
                  padding: "5px 10px",
                  background: "rgb(50 199 161)",
                  width: "90px",
                  wordBreak: "unset",
                  color: "#7365e6",
                  backgroundColor: "#e7e5fa",
                  textAlign: "center",
                }}
                variant={"caption"}
                bold
              >
                Post Similar
              </Text>
            </PostTaskWrapper>
          </div>
        </div>
      );
    case "has-offer":
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "auto",
            marginBottom: "auto",
          }}
        >
          {RenderOffersAvatars(task)}
          <div
            style={{
              display: "flex",
              cursor: "pointer",
              flexDirection: "column",
              width: "fit-content",
              marginBottom: "auto",
              marginRight: "15px",
            }}
          >
            <MakeOffer
              task={task}
              offer={new TaskOffer(tasksOffersAvatars.find((item) => item.taskId === task?.id))}
            >
              <Text
                style={{
                  ...(styles.statusIndicator as React.CSSProperties),
                  padding: "5px 10px",
                  background: "rgb(50 199 161)",
                  width: "84px",
                  wordBreak: "unset",
                  color: "white",
                  textAlign: "center",
                  backgroundColor: mainPaletteColor,
                }}
                variant={"caption"}
                bold
              >
                Update Offer
              </Text>
            </MakeOffer>
          </div>
        </div>
      );
    case "leave-review-owner":
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "auto",
            marginBottom: "auto",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "fit-content",
              marginBottom: "auto",
              marginLeft: "15px",
            }}
          >
            <div style={{display: "flex", flexDirection: "row"}}>
              <Avatar
                profilePicture={task?.assignedWorker?.profilePicture}
                name={task?.assignedWorker?.name}
                size={20}
                style={{
                  margin: "auto",
                }}
              />
              <Text variant={"body2"} medium style={{color: "gray", marginLeft: "10px"}}>
                Completed by{" "}
              </Text>
              <Text variant={"body2"} medium bold style={{color: "black"}}>
                {task.assignedWorker.name.split(" ")[0]}
              </Text>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              cursor: "pointer",
              flexDirection: "column",
              width: "fit-content",
              marginBottom: "auto",
              marginRight: "15px",
            }}
          >
            <Text
              onClick={() => {
                navigate(`/my-tasks/poster/${task?.id}/leave-review-for-tasker`);
              }}
              style={{
                ...(styles.statusIndicator as React.CSSProperties),
                padding: "5px 10px",
                width: "100px",
                wordBreak: "unset",
                background: "rgb(50 199 161)",
                backgroundColor: "#faf5e2",
                color: "#e79829",
                textAlign: "center",
              }}
              variant={"caption"}
              bold
            >
              Leave Review
            </Text>
          </div>
        </div>
      );
    case "assigned-to-tasker":
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "auto",
            marginBottom: "auto",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "fit-content",
              marginBottom: "auto",
              marginLeft: "15px",
            }}
          >
            <div style={{display: "flex", flexDirection: "row"}}>
              <Text variant={"body2"} medium style={{color: "gray"}}>
                You'll earn{" "}
              </Text>
              <Text variant={"body2"} medium style={{color: "black"}}>
                {currencySymbol}
                <NumericFormat
                  value={parseFloat(task.assignedWorker.totalTransferred).toFixed(0)}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={""}
                />
              </Text>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              cursor: "pointer",
              flexDirection: "column",
              width: "fit-content",
              marginBottom: "auto",
              marginRight: "15px",
            }}
          >
            <Text
              onClick={() => {
                navigate(`/messaging/${task?.id}_${task?.assignedWorker?.uid}`);
              }}
              style={{
                ...(styles.statusIndicator as React.CSSProperties),
                padding: "5px 10px",
                background: "rgb(50 199 161)",
                width: "102px",
                wordBreak: "unset",
                color: "white",
                textAlign: "center",
                backgroundColor: mainPaletteColor,
              }}
              variant={"caption"}
              bold
            >
              Send Message
            </Text>
          </div>
        </div>
      );
    case "leave-review-tasker":
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "auto",
            marginBottom: "auto",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "fit-content",
              marginBottom: "auto",
              marginLeft: "15px",
            }}
          >
            <div style={{display: "flex", flexDirection: "row"}}>
              <Avatar
                profilePicture={task?.assignedWorker?.profilePicture}
                name={task?.taskOwner?.name}
                size={20}
                style={{
                  margin: "auto",
                }}
              />
              <Text variant={"body2"} medium style={{color: "gray", marginLeft: "10px"}}>
                Posted by{" "}
              </Text>
              <Text variant={"body2"} medium bold style={{color: "black"}}>
                {task.taskOwner.name.split(" ")[0]}
              </Text>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              cursor: "pointer",
              flexDirection: "column",
              width: "fit-content",
              marginBottom: "auto",
              marginRight: "15px",
            }}
          >
            <Text
              onClick={() => {
                navigate(`/my-tasks/tasker/${task?.id}/leave-review-for-poster`);
              }}
              style={{
                ...(styles.statusIndicator as React.CSSProperties),
                padding: "5px 10px",
                background: "rgb(50 199 161)",
                width: "100px",
                wordBreak: "unset",
                backgroundColor: "#faf5e2",
                color: "#e79829",
                textAlign: "center",
              }}
              variant={"caption"}
              bold
            >
              Leave Review
            </Text>
          </div>
        </div>
      );
  }
};

export default TaskStatusIndicator;